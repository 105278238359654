<template>
  <div class="chart-info w-full relative">
    <template v-if="!isLoading">
      <div class="flex flex-row items-start">
        <div class="relative w-full">
          <vs-list-header :title="$t('views.home.totalVisitorsWithBuyingIntent')" class="card-title-font-size" color="primary"></vs-list-header>
          <div class="chart-info__subtitle">{{ $t('views.home.visitorsWithBuyingIntentSubtitle') }}</div>
        </div>
        <div class="ml-2 vs-list--header">
          <vx-tooltip style="width: 17px" :text="$t('info.dashboard.totalVisitorsWithBuyingIntentInfo')" position="top">
            <img width="17" height="17" :src="infoImg" />
          </vx-tooltip>
        </div>
      </div>

      <vs-tabs :value="activeTab" :v-model="activeTab" position="top" alignment="center" class="pt-5 tabs-shadow-none tabs-visitor-data" :key="key">
        <vs-tab :label="$t('views.home.allVisitors')">
          <div style="position: relative; height: 180px">
            <vue-apex-charts type="radialBar" height="270" :options="chartOptionsAll" :series="seriesAll" />

            <div class="chart-label">{{ $t('views.home.visitorsShowedIntentionToBuy') }}</div>
          </div>

          <div class="visitor-data-list">
            <div v-for="item in visitorStatusOverviewDataAll" :key="item.name" class="visitor-data-list__item">
              <VegaIcon :type="item.type" :width="21" />

              <div>
                <span class="visitor-data-list__item__title">{{ item.name }}</span>
                -
                <span class="visitor-data-list__item__percent"> {{ item.percent }}%</span>
              </div>
            </div>
          </div>
        </vs-tab>

        <vs-tab :label="$t('views.home.businessVisitors')">
          <div style="position: relative; height: 180px">
            <vue-apex-charts type="radialBar" height="270" :options="chartOptionsBusiness" :series="seriesBusiness" />

            <div class="chart-label">{{ $t('views.home.businessVisitorsShowedIntentionToBuy') }}</div>
          </div>

          <div class="visitor-data-list">
            <div v-for="item in visitorStatusOverviewDataBusiness" :key="item.name" class="visitor-data-list__item">
              <VegaIcon :type="item.type" :width="21" />

              <div>
                <span class="visitor-data-list__item__title">{{ item.name }}</span>
                -
                <span class="visitor-data-list__item__percent"> {{ item.percent }}%</span>
              </div>
            </div>
          </div>
        </vs-tab>

        <vs-tab :label="$t('views.home.privateVisitors')">
          <div style="position: relative; height: 180px">
            <vue-apex-charts type="radialBar" height="270" :options="chartOptionsPrivate" :series="seriesPrivate" />

            <div class="chart-label">{{ $t('views.home.privateVisitorsShowedIntentionToBuy') }}</div>
          </div>

          <div class="visitor-data-list">
            <div v-for="item in visitorStatusOverviewDataPrivate" :key="item.name" class="visitor-data-list__item">
              <VegaIcon :type="item.type" :width="21" />

              <div>
                <span class="visitor-data-list__item__title">{{ item.name }}</span>
                -
                <span class="visitor-data-list__item__percent"> {{ item.percent }}%</span>
              </div>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </template>

    <placeholder-text-small v-else />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

import { analyticsData } from './analyticsData'
import VegaIcon from './VegaIcon.vue'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

export default {
  components: {
    VueApexCharts,
    VegaIcon,
    PlaceholderTextSmall
  },

  mixins: [analyticsData],

  props: {
    title: {
      type: String,
      default: 'Item Title'
    },
    isLoading: {
      type: Boolean
    }
  },

  data() {
    return {
      activeTab: 0,
      key: Math.random().toString(36).substring(2, 15),
      infoImg: require('@/assets/images/elements/info.svg'),
      demoSeries: [29],
      demoVisitorStatusOverviewData: [
        {
          type: 'onfire',
          name: 'On Fire',
          percent: '10,5'
        },
        {
          type: 'hot',
          name: 'Hot',
          percent: '18,2'
        },
        {
          type: 'warm',
          name: 'Warm',
          percent: '7'
        },
        {
          type: 'mild',
          name: 'Mild',
          percent: '30,1'
        },
        {
          type: 'cold',
          name: 'Cold',
          percent: '23,5'
        },
        {
          type: 'frozen',
          name: 'Frozen',
          percent: '10,7'
        }
      ]
    }
  },

  computed: {
    vegaVisitorAnalyticsBusiness() {
      if (!this.vegaVisitorAnalytics) {
        return []
      }

      return this.vegaVisitorAnalytics.filter((item) => item.b2b)
    },

    vegaVisitorAnalyticsPrivate() {
      if (!this.vegaVisitorAnalytics) {
        return []
      }

      return this.vegaVisitorAnalytics.filter((item) => !item.b2b)
    },

    seriesAll() {
      if (!this.isVegaActivated) {
        return this.demoSeries
      }

      if (!this.vegaVisitorAnalytics) {
        return [0]
      }

      return [this.calculateVisitorsWithBuyingIntentInPercent(this.vegaVisitorAnalytics)]
    },

    seriesBusiness() {
      if (!this.isVegaActivated) {
        return this.demoSeries
      }

      if (!this.vegaVisitorAnalyticsBusiness) {
        return [0]
      }

      return [this.calculateVisitorsWithBuyingIntentInPercent(this.vegaVisitorAnalyticsBusiness)]
    },

    seriesPrivate() {
      if (!this.isVegaActivated) {
        return this.demoSeries
      }

      if (!this.vegaVisitorAnalyticsPrivate) {
        return [0]
      }

      return [this.calculateVisitorsWithBuyingIntentInPercent(this.vegaVisitorAnalyticsPrivate)]
    },

    chartOptionsAll() {
      return {
        chart: {
          type: 'radialBar',
          offsetY: 0,
          sparkline: {
            enabled: false
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#DEDDE0',
              strokeWidth: '60%',
              margin: 18
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                color: '#726F7B',
                offsetY: -10,
                fontSize: '20px'
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        stroke: {
          lineCap: 'round'
        },
        fill: {
          colors: ['#3034F7']
        },
        labels: ['']
      }
    },

    chartOptionsBusiness() {
      return {
        chart: {
          type: 'radialBar',
          offsetY: 0,
          sparkline: {
            enabled: false
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#DEDDE0',
              strokeWidth: '60%',
              margin: 18
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                color: '#726F7B',
                offsetY: -10,
                fontSize: '20px'
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        stroke: {
          lineCap: 'round'
        },
        fill: {
          colors: ['#3034F7']
        },
        labels: ['']
      }
    },

    chartOptionsPrivate() {
      return {
        chart: {
          type: 'radialBar',
          offsetY: 0,
          sparkline: {
            enabled: false
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#DEDDE0',
              strokeWidth: '60%',
              margin: 18
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                color: '#726F7B',
                offsetY: -10,
                fontSize: '20px'
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        stroke: {
          lineCap: 'round'
        },
        fill: {
          colors: ['#3034F7']
        },
        labels: ['']
      }
    },

    visitorStatusOverviewDataAll() {
      if (!this.isVegaActivated) {
        return this.demoVisitorStatusOverviewData
      }

      const vegaData = this.vegaVisitorAnalytics

      let demoModifiedData = this.demoVisitorStatusOverviewData.map((item) => {
        item.percent = 0
        item.amount = 0

        return { ...item }
      })

      if (!vegaData) {
        return demoModifiedData
      }

      const totalVisitorsCount = vegaData.reduce((accumulator, currentValue) => accumulator + currentValue.visitor_count, 0)

      vegaData.forEach((vegaDataItem) => {
        demoModifiedData = demoModifiedData.map((item) => {
          if (item && vegaDataItem && vegaDataItem.visitor_count && item.type === vegaDataItem.score_category) {
            item = {
              ...item,
              amount: item.amount + vegaDataItem.visitor_count
            }
          }

          return item
        })
      })

      demoModifiedData.forEach((item) => {
        let percent = 0

        if (totalVisitorsCount && item.amount) {
          const calculatedPercent = Math.round((item.amount / totalVisitorsCount) * 100)

          if (!isNaN(calculatedPercent)) {
            percent = calculatedPercent
          }
        }

        item.percent = percent
      })

      return demoModifiedData
    },

    visitorStatusOverviewDataBusiness() {
      if (!this.isVegaActivated) {
        return this.demoVisitorStatusOverviewData
      }

      const vegaData = this.vegaVisitorAnalyticsBusiness

      let demoModifiedData = this.demoVisitorStatusOverviewData.map((item) => {
        item.percent = 0
        item.amount = 0

        return { ...item }
      })

      if (!vegaData) {
        return demoModifiedData
      }

      const totalVisitorsCount = vegaData.reduce((accumulator, currentValue) => accumulator + currentValue.visitor_count, 0)

      vegaData.forEach((vegaDataItem) => {
        demoModifiedData = demoModifiedData.map((item) => {
          if (item && vegaDataItem && vegaDataItem.visitor_count && item.type === vegaDataItem.score_category) {
            item = {
              ...item,
              amount: item.amount + vegaDataItem.visitor_count
            }
          }

          return item
        })
      })

      demoModifiedData.forEach((item) => {
        let percent = 0

        if (totalVisitorsCount && item.amount) {
          const calculatedPercent = Math.round((item.amount / totalVisitorsCount) * 100)

          if (!isNaN(calculatedPercent)) {
            percent = calculatedPercent
          }
        }

        item.percent = percent
      })

      return demoModifiedData
    },

    visitorStatusOverviewDataPrivate() {
      if (!this.isVegaActivated) {
        return this.demoVisitorStatusOverviewData
      }

      const vegaData = this.vegaVisitorAnalyticsPrivate

      let demoModifiedData = this.demoVisitorStatusOverviewData.map((item) => {
        item.percent = 0
        item.amount = 0

        return { ...item }
      })

      if (!vegaData) {
        return demoModifiedData
      }

      const totalVisitorsCount = vegaData.reduce((accumulator, currentValue) => accumulator + currentValue.visitor_count, 0)

      vegaData.forEach((vegaDataItem) => {
        demoModifiedData = demoModifiedData.map((item) => {
          if (item && vegaDataItem && vegaDataItem.visitor_count && item.type === vegaDataItem.score_category) {
            item = {
              ...item,
              amount: item.amount + vegaDataItem.visitor_count
            }
          }

          return item
        })
      })

      demoModifiedData.forEach((item) => {
        let percent = 0

        if (totalVisitorsCount && item.amount) {
          const calculatedPercent = Math.round((item.amount / totalVisitorsCount) * 100)

          if (!isNaN(calculatedPercent)) {
            percent = calculatedPercent
          }
        }

        item.percent = percent
      })

      return demoModifiedData
    }
  },

  methods: {
    calculateVisitorsWithBuyingIntentInPercent(data) {
      if (!data) {
        return [0]
      }

      let totalWebsiteVisitorsCount = 0
      let totalVisitorsWithBuyingIntentCount = 0
      let visitorsWithBuyingIntentInPercent = 0

      totalWebsiteVisitorsCount = data.reduce((accumulator, currentValue) => accumulator + currentValue.visitor_count, 0)

      const totalVisitorsWithBuyingIntentArray = data.filter((item) => this.selectedVegaPopupThreshold.includes(item.score_category))
      totalVisitorsWithBuyingIntentCount = totalVisitorsWithBuyingIntentArray.reduce((accumulator, currentValue) => accumulator + currentValue.visitor_count, 0)
      visitorsWithBuyingIntentInPercent = Math.round((totalVisitorsWithBuyingIntentCount / totalWebsiteVisitorsCount) * 100)
      if (isNaN(visitorsWithBuyingIntentInPercent)) {
        visitorsWithBuyingIntentInPercent = 0
      }

      return visitorsWithBuyingIntentInPercent
    }
  }
}
</script>

<style lang="scss">
.apexcharts-datalabel-label {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
}
.tabs-visitor-data {
  margin: 0 -20px !important;
  width: calc(100% + 40px);

  .ul-tabs {
    flex-wrap: nowrap !important;

    .vs-tabs {
      &--li {
        border-bottom: 1px solid #f8f8f8 !important;

        .vs-tabs--btn {
          font-size: 13px !important;
          line-height: 16px !important;
          color: #7f7c87 !important;
        }
      }
    }
  }

  .con-ul-tabs {
    .line-vs-tabs {
      top: 39px !important;
      height: 3px !important;
    }
  }

  .vs-tabs {
    &--content {
      padding: 10px 1.5rem !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 6px;
}

.chart-label {
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: -32px auto 0;
  width: 155px;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #c2c0c5;
  transform: translateX(-50%);
}

.visitor-data-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  &__item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 50%;
    font-size: 14px;
    color: #767480;

    &__title {
      margin-left: 10px;
      font-weight: 700;
    }
  }
}
</style>
